

import __layout_0 from '/codebuild/output/src3405362072/src/tenant_portal/src/layouts/default.vue'
export const layouts = {
'blank': () => import('/codebuild/output/src3405362072/src/tenant_portal/src/layouts/blank.vue'),
'default': __layout_0,
'components/DefaultLayoutWithHorizontalNav': () => import('/codebuild/output/src3405362072/src/tenant_portal/src/layouts/components/DefaultLayoutWithHorizontalNav.vue'),
'components/DefaultLayoutWithVerticalNav': () => import('/codebuild/output/src3405362072/src/tenant_portal/src/layouts/components/DefaultLayoutWithVerticalNav.vue'),
'components/Footer': () => import('/codebuild/output/src3405362072/src/tenant_portal/src/layouts/components/Footer.vue'),
'components/NavBarNotifications': () => import('/codebuild/output/src3405362072/src/tenant_portal/src/layouts/components/NavBarNotifications.vue'),
'components/NavSearchBar': () => import('/codebuild/output/src3405362072/src/tenant_portal/src/layouts/components/NavSearchBar.vue'),
'components/NavbarShortcuts': () => import('/codebuild/output/src3405362072/src/tenant_portal/src/layouts/components/NavbarShortcuts.vue'),
'components/NavbarThemeSwitcher': () => import('/codebuild/output/src3405362072/src/tenant_portal/src/layouts/components/NavbarThemeSwitcher.vue'),
'components/UserProfile': () => import('/codebuild/output/src3405362072/src/tenant_portal/src/layouts/components/UserProfile.vue'),
}
export const createGetRoutes = (router, withLayout = false) => {
  const routes = router.getRoutes()
  if (withLayout) {
      return routes
  }
  return () => routes.filter(route => !route.meta.isLayout)
}

export function setupLayouts(routes) {
  function deepSetupLayout(routes, top = true) {
    return routes.map(route => {
      if (route.children?.length > 0) {
        route.children = deepSetupLayout(route.children, false)
      }
      
      if (top && route.meta?.layout !== false) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout || 'default'],
          children: [ {...route, path: ''} ],
          meta: {
            isLayout: true
          }
        }
      }

      if (route.meta?.layout) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout],
          children: [ {...route, path: ''} ],
          meta: {
            isLayout: true
          }
        }
      }

      return route
    })
  }

    return deepSetupLayout(routes)

}
